import { faGlobe } from '@fortawesome/free-solid-svg-icons/faGlobe';
import { Dropdown } from 'antd';
import { MenuProps } from 'antd/es/menu/menu';
import React from 'react';
import styled from 'styled-components';

import { Images } from '../assets';
import i18n, { AppLangs, replaceLangPathInUrl } from '../i18n';
import { useAppSelector } from '../redux/store';
import { userSliceSelectors } from '../redux/user/user.selector';
import { Spacing } from '../theme/Spacing';
import { AtiraIcon } from './AtiraIcon';
import { Button } from './Button';
import { Flex } from './Flex';
import { AtiraImage } from './Image';
import { Text } from './Text';

const LanguageWrapper = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  gap: ${Spacing.s};
`;

const currentLanguage = i18n.language.toUpperCase();

export const LanguageSwitcher: React.FC = () => {
  const isLoggedIn = useAppSelector(userSliceSelectors.selectLoggedInUserId!);

  const changeLanguage = (lang: AppLangs) => {
    if (!isLoggedIn) {
      replaceLangPathInUrl(lang);
    }

    setTimeout(() => {
      i18n.changeLanguage(lang);
    }, 0);
  };

  const items: MenuProps['items'] = [
    {
      key: 'g1',
      type: 'group',
      children: [
        {
          key: 'en',
          label: (
            <LanguageWrapper>
              <Text>{AppLangs.EN.toUpperCase()}</Text>
              <AtiraImage src={Images.USAFlag} width={20} />
            </LanguageWrapper>
          ),
          onClick: () => changeLanguage(AppLangs.EN),
        },
        {
          key: 'ar',
          label: (
            <LanguageWrapper>
              <Text>ع</Text>
              <AtiraImage src={Images.SaudiFlag} width={20} />
            </LanguageWrapper>
          ),
          onClick: () => changeLanguage(AppLangs.AR),
        },
        {
          key: 'tr',
          label: (
            <LanguageWrapper>
              <Text>{AppLangs.TR.toUpperCase()}</Text>
              <AtiraImage src={Images.TurkishFlag} width={20} />
            </LanguageWrapper>
          ),
          onClick: () => changeLanguage(AppLangs.TR),
        },
      ],
    },
  ];

  return (
    <Dropdown
      menu={{ items }}
      trigger={['click']}
      arrow={{ pointAtCenter: true }}
      placement="bottom"
    >
      <Button padding="0" margin="0">
        <Flex alignItems="center" gap="s">
          <AtiraIcon icon={faGlobe} size="2x" color="sub" />
          <Text color="white">
            {currentLanguage.toLowerCase() === AppLangs.AR
              ? 'ع'
              : currentLanguage}
          </Text>
        </Flex>
      </Button>
    </Dropdown>
  );
};
