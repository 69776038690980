import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AtiraEmpty } from '../../../components/AtiraEmpty';
import { Button } from '../../../components/Button';
import { Flex } from '../../../components/Flex';
import { SpinnerFullScreen } from '../../../components/SpinnerFullScreen';
import { Text } from '../../../components/Text';
import { adminSliceSelectors } from '../../../redux/admin/admin.selector';
import { adminActions } from '../../../redux/admin/admin.slice';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { userSliceSelectors } from '../../../redux/user/user.selector';
import { AtiraToast } from '../../../utils/AtiraToast';
import { AdminMessage } from './AdminMessage';

const MarkButton = styled(Button)`
  height: 2.5rem;
  font-size: 1rem;
  width: fit-content;

  &:hover {
    background-color: ${({ theme }) => theme.lightMain};
  }
`;

export const NotificationsAdminMessagesTab: React.FC = () => {
  const [markAsReadLoading, setMarkAsReadLoading] = useState(false);

  const { t } = useTranslation(undefined, {
    keyPrefix: 'header.bell.drawer.adminMessages',
  });
  const dispatch = useAppDispatch();

  const adminMessages = useAppSelector(adminSliceSelectors.selectAdminMessages);
  const adminMessagesLoading = useAppSelector(
    adminSliceSelectors.selectAdminMessagesLoading,
  );
  const adminMessagesPage = useAppSelector(
    adminSliceSelectors.selectAdminMessagesPage,
  );
  const adminMessagesPageSize = useAppSelector(
    adminSliceSelectors.selectAdminMessagesPageSize,
  );
  const userId = useAppSelector(userSliceSelectors.selectLoggedInUserId)!;

  const onMarkAsRead = async () => {
    try {
      setMarkAsReadLoading(true);
      await dispatch(adminActions.markAdminMessagesAsRead({ userId })).unwrap();
      await dispatch(
        adminActions.getAdminMessages({
          userId,
          meta: { page: adminMessagesPage - 1, count: adminMessagesPageSize },
        }),
      ).unwrap();
    } catch (e: any) {
      AtiraToast.apiError(e);
      console.log(e);
    } finally {
      setMarkAsReadLoading(false);
    }
  };

  if (adminMessagesLoading && !adminMessages?.length) {
    return <SpinnerFullScreen />;
  }

  return (
    <Flex flexDirection="column" gap="m" position="relative">
      <Flex justifyContent="space-between" alignItems="center">
        <Text>{t('title')}</Text>
        <MarkButton loading={markAsReadLoading} onClick={onMarkAsRead}>
          {t('mark_as_read')}
        </MarkButton>
      </Flex>
      {adminMessages?.length ? (
        <Flex flexDirection="column" gap="s">
          {adminMessages.map((message) => (
            <AdminMessage key={message._id} message={message} />
          ))}
        </Flex>
      ) : (
        <AtiraEmpty description={t('empty')} />
      )}
    </Flex>
  );
};
