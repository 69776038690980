import { Col } from 'antd';
import React from 'react';

interface AtiraColumnProps {
  xs?: number | { order?: number; span?: number; offset?: number };
  sm?: number | { order?: number; span?: number; offset?: number };
  md?: number | { order?: number; span?: number; offset?: number };
  lg?: number | { order?: number; span?: number; offset?: number };
  xl?: number | { order?: number; span?: number; offset?: number };
  span?: number;
  order?: number;
  children?: React.ReactNode;
  className?: string;
}

export const AtiraColumn: React.FC<AtiraColumnProps> = ({
  xs = 24,
  sm = 24,
  md = 8,
  lg = 8,
  xl = 10,
  order,
  span,
  children,
  className,
}) => {
  return (
    <Col
      xs={xs}
      sm={sm}
      md={md}
      lg={lg}
      xl={xl}
      span={span}
      className={className}
    >
      {children}
    </Col>
  );
};
