import AtiraGrupIcon from './atira-grup-icon.svg';
import GoogleIcon from './google.svg';
import SaudiFlag from './saudi-flag.svg';
import TurkishFlag from './turkish-flag.svg';
import USAFlag from './usa-flag.svg';

export const Images = {
  SaudiFlag,
  USAFlag,
  TurkishFlag,
  HeroPhoto: require('./hero.jpg'),
  HeroBackground: require('./hero-bg.jpg'),
  FormTheme1: require('./form-theme1.png'),
  FormTheme2: require('./form-theme2.png'),
  FormTheme3: require('./form-theme3.png'),
  FormTheme4: require('./form-theme4.png'),
  FormTheme5: require('./form-theme5.png'),
  FormTheme6: require('./form-theme6.png'),
  FormTheme7: require('./form-theme7.png'),
  AtiraSpaceLogoBetaSub: require('./atiraspace-beta-sub.png'),
  AtiraSpaceLogoBetaMain: require('./atiraspace-beta-main.png'),
  AtiraSpaceLogoSub: require('./atiraspace-sub.png'),
  AtiraSpaceLogoMain: require('./atiraspace-main.png'),
  HomeBackground: require('./home-bg.jpg'),
  HomeFirstCircle: require('./hero-first-circle-img.png'),
  HomeSecondCircle: require('./hero-second-circle-img.png'),
  HomeFirstSection: require('./home-first-section.jpg'),
  HomeSecondSection: require('./home-second-section.jpg'),
  TransparentPlaceholder: require('./transparent-placeholder.png'),
  PricingHeroImage: require('./pricing-hero-img.png'),
  PricingAddOnsImage: require('./pricing-add-ons-img.png'),
  PricingPaidPlansImage: require('./pricing-paid-plans-img.png'),
  FeaturesPageBackground: require('./features-page-bg.png'),
  FeaturesPageContactManagement: require('./features-page-contact-management.png'),
  FeaturesPageSalesPipeline: require('./features-page-sales-pipeline.png'),
  FeaturesPageTaskWorkflowAutomation: require('./features-page-task-workflow-automation.png'),
  FeaturesPageReportingAnalytics: require('./features-page-reporting-analytics.png'),
  FeaturesPageEmailMarketingAutomation: require('./features-page-email-marketing-automation.png'),
  FeaturesPageCalendarAppointmentScheduling: require('./features-page-calendar-appointment-scheduling.png'),
  FeaturesPageLeadManagementScoring: require('./features-page-lead-management-scoring.png'),
  FeaturesPageCustomizationFlexibility: require('./features-page-customization-flexibility.png'),
  FeaturesPageMobileCRMAccess: require('./features-page-mobile-crm-access.png'),
  FeaturesPageIntegrationCapabilities: require('./features-page-integration-capabilities.png'),
  CasesHeroBackground: require('./cases-hero-bg.png'),
  CasesFeatures: require('./cases-features.png'),
  CasesIndustryRealEstate: require('./cases-industry-real-estate.png'),
  CasesIndustryECommerce: require('./cases-industry-e-commerce.png'),
  CasesIndustryEducation: require('./cases-industry-education.png'),
  CasesIndustryHealth: require('./cases-industry-health.png'),
  CasesIndustryMedical: require('./cases-industry-medical.png'),
  CasesIndustryServices: require('./cases-industry-services.png'),
  CasesIndustryTravel: require('./cases-industry-travel.png'),
  CasesManageBusiness: require('./cases-manage-business.png'),
  CasesWhyChoose: require('./cases-why-choose.png'),
  //
  ResourcesHeroImage: require('./resources-hero.png'),
  Resources1Image: require('./resources-1.png'),
  Resources2Image: require('./resources-2.png'),
  Resources3Image: require('./resources-3.png'),
  Resources5Background: require('./resources-5-bg.png'),
  ResourcesLightBackground: require('./resources-light-bg.png'),
  ResourcesBlog1Image: require('./resources-blog-1.jpeg'),
  //
  AtiraGrupIcon,
  GoogleIcon,
};
