import React from 'react';
import styled from 'styled-components';

import { Flex } from '../../components/Flex';
import { Breakpoints } from '../../theme/Breakpoints';
import { Rounded } from '../../theme/Rounded';
import { Shadow } from '../../theme/Shadow';
import { Spacing } from '../../theme/Spacing';
import { Calculator } from './components/Calculator';

const Container = styled(Flex)`
  width: 90%;
  margin: auto;
  box-shadow: ${Shadow.MD};
  padding: ${Spacing.m};
  background-color: ${({ theme }) => theme.white};
  border-radius: ${Rounded.md};

  @media (min-width: ${Breakpoints.DESKTOP}) {
    width: 70%;
  }

  @media (min-width: ${Breakpoints.LARGE_DESKTOP}) {
    width: 45%;
  }
`;

export const CalculatorRoute: React.FC = () => {
  return (
    <Container>
      <Calculator />
    </Container>
  );
};
