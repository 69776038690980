import { RouteObject } from 'react-router-dom';

import { AtiraRouteIndex } from '../AtiraRouteIndex';
import { AtiraRouteNotLoggedIn } from '../AtiraRouteNotLoggedIn';
import { PublicLayout } from '../PublicLayout';
import { FeaturesRouteWithLazy } from '../features/FeaturesRouteWithLazy';
import { LandingPageRouteWithLazy } from '../landing-page/LandingPageRouteWithLazy';
import { Login } from '../loginSignup/Login';
import { ForgotPassword } from '../loginSignup/forgot-password/ForgotPassword';
import { Signup } from '../loginSignup/signup/Signup';
import { InviteLandingPage } from '../permissions/invite/InvitesLandingPage';
import { PricingRouteWithLazy } from '../pricing/PricingRouteWithLazy';
import { ResourcesRouteWithLazy } from '../resources/ResourcesWithLazy';
import { UseCasesRouteWithLazy } from '../use-cases/UseCasesRouteWithLazy';

export const PublicRoutesRecord = {
  home: '/',
  features: '/features',
  pricing: '/pricing',
  useCases: '/use-cases',
  resources: '/resources',
  login: '/login',
  signup: '/signup',
  forgotPassword: '/forgot-password',
  permissionInvite: '/permissions/invite',
};

export const PublicRouter: RouteObject[] = [
  {
    element: <PublicLayout />,
    errorElement: (
      <div>Something went wrong... Please try refreshing the page</div>
    ),
    children: [
      {
        path: PublicRoutesRecord.home,
        element: <AtiraRouteIndex component={<LandingPageRouteWithLazy />} />,
      },
      {
        path: PublicRoutesRecord.features,
        element: (
          <AtiraRouteNotLoggedIn component={<FeaturesRouteWithLazy />} />
        ),
      },
      {
        path: PublicRoutesRecord.pricing,
        element: <AtiraRouteNotLoggedIn component={<PricingRouteWithLazy />} />,
      },
      {
        path: PublicRoutesRecord.useCases,
        element: (
          <AtiraRouteNotLoggedIn component={<UseCasesRouteWithLazy />} />
        ),
      },
      {
        path: PublicRoutesRecord.resources,
        element: (
          <AtiraRouteNotLoggedIn component={<ResourcesRouteWithLazy />} />
        ),
      },
    ],
  },
  {
    path: PublicRoutesRecord.login,
    element: <AtiraRouteNotLoggedIn component={<Login />} />,
  },
  {
    path: PublicRoutesRecord.signup,
    element: <AtiraRouteNotLoggedIn component={<Signup />} />,
  },
  {
    path: PublicRoutesRecord.forgotPassword,
    element: <AtiraRouteNotLoggedIn component={<ForgotPassword />} />,
  },
  {
    path: PublicRoutesRecord.permissionInvite,
    element: <InviteLandingPage />,
  },
];
