import { truncate } from 'lodash';

import i18n from '../i18n';

export const pluralize = (count: number, noun: string, suffix = 's') => {
  const lang = i18n.language;
  if (lang === 'en') {
    return `${noun}${count !== 1 ? suffix : ''}`;
  }
  return noun;
};

export const truncateString = (value: string, length?: number) => {
  const maxLength = length || 20;

  if (value && value.length >= maxLength) {
    return truncate(value, { length: maxLength });
  }

  return value;
};

export const Regexes = {
  EMAIL_REGEX: /^\s*[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}\s*$/,
  NAME_REGEX:
    /^(?![-._\s])[a-zA-Z0-9\u0600-\u06FF\u0750-\u077F\u08A0][-._a-zA-Z0-9\u0600-\u06FF\u0750-\u077F\u08A0\s]{1,18}(?<![-._\s])$/,
  URL_REGEX:
    /^\s*(https?:\/\/)?(([a-zA-Z0-9-]+\.)+[a-zA-Z0-9-]{2,6}|((([0-9]{1,3}\.){3}[0-9]{1,3}))(:[0-9]{1,5})?)(\/[a-zA-Z0-9\-._~:/?#[\]@!$&'()*+,;=%]*)?\s*$/,
  PHONE_REGEX: /^\s*[+]?([0-9\s]*)\s*$/,
  DOMAIN_REGEX:
    /^\s*(?!-)(?!.*-$)(?!.*--)[A-Za-z0-9-]{1,63}(\.[A-Za-z0-9-]{1,63})*(\.[A-Za-z]{2,})\s*$/,
};

export const isValidUrl = (url: string) => {
  try {
    new URL(url);
    return true;
  } catch (_) {
    return false;
  }
};
